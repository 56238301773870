/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    var get_events = function() {

        // Show the loading spinner
        var loaderSource = $("#event-loader").html(); // Handlebars template defined in head.php
        var loaderTemplate = Handlebars.compile(loaderSource);
        $("#swra-events").html(loaderTemplate);

        $.post(ajaxurl, {
            // Get all of the events using the custom_get_events function in functions.php
            action: 'custom_get_events',
            category: $('[data-filter="category"]').data("current"),
            month: $('[data-filter="month"]').data("current")
        }, function(response) {
            // Load all of the events using Handlebars
            var source = $("#event-template").html(); // Handlebars template defined in head.php
            var template = Handlebars.compile(source);
            $("#swra-events").html(template(JSON.parse(response)));
        });
    };

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            // JavaScript to be fired on all pages
            init: function() {

                // Add dropdown classes to the header navigation
                $('.dropdown').hover(function() {
                        $(this).addClass('open');
                    },
                    function() {
                        $(this).removeClass('open');
                    });

                // Rotate the top level navigation arrows
                $('#menu-main-navigation')
                    .children('li.dropdown')
                    .children('a.dropdown-toggle')
                    .find('i')
                    .removeClass('fa-rotate-270');

                // Initiate the mobile nav slider              
                $("#menu-main-navigation-container").mmenu({
                    'navbar': {
                        'title': ''
                    },
                    'preventDefault': true
                });

                // add title attributes to mobile menu submenu options
                $("a.mm-next").each(function(index) {
                    var title = "View " + $(this).next("a").text() + " submenu";
                    $(this).attr("title", title);
                    $(this).next("a").attr("title", title);
                });

                $("a.mm-prev").attr("title", "Return to parent menu");

                $(".mm-listview li.menu-item-has-children a").on("click", function(event) {
                    if (!$(this).hasClass("mm-next")) {
                        event.preventDefault();
                        event.stopPropagation();
                        $(this).prev().click();
                    }
                });

                // Calendar Functions

                // Update the events based on filter criteria
                $(".filter-options li a").on("click", function(event) {

                    event.preventDefault();

                    // Get the new value
                    var value = $(this).parent("li").data("value");
                    var logo = $(this).parent("li").data("logo");
                    var text = $(this).text();

                    // Set the new value
                    var currentFilter = $(this).parents("[data-filter]");
                    var currentFilterOption = $(currentFilter).data("filter");
                    var allFilters = $("[data-filter='" + currentFilterOption + "']");

                    $(allFilters).data("current", value);
                    $(allFilters).find("a.filter-toggle").html(text + '<i class="fa fa-arrow-circle-right fa-rotate-90"></i>');

                    if(text === "All Categories") {
                        $(".events-header, .page-header h1").text("All Upcoming Events");
                    } else {
                        $(".events-header, .page-header h1").text("Events Category: " + text);
                    }

                    if (logo == 'ars') {
                        $("#header-logo").attr("data-logo", "show-ars-logo");
                    } else {
                        $("#header-logo").attr("data-logo", "show-default-logo");
                    }

                    $(currentFilter).find(".filter-options").slideUp("fast");

                    get_events();
                });

                $("[data-toggle]").click(function(e) {
                    e.preventDefault();
                    $(this).find("i").toggleClass("fa-rotate-90");
                    $(this).next(".filter-options").slideToggle("fast", function() {});
                });
            },
            // JavaScript to be fired on all pages, after page specific JS is fired
            finalize: function() {}
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
                var options = {
                    $FillMode: 2, // cover
                    $AutoPlay: true,
                    $AutoPlayInterval: 4000,
                    $PauseOnHover: 1,
                    $SlideEasing: $JssorEasing$.$EaseOutQuint, //[Optional] Specifies easing for right to left animation, default value is $JssorEasing$.$EaseOutQuad
                    $SlideDuration: 800,
                    $BulletNavigatorOptions: {
                        $Class: $JssorBulletNavigator$, //[Required] Class to create navigator instance
                        $ChanceToShow: 2, //[Required] 0 Never, 1 Mouse Over, 2 Always
                    },
                    $ArrowNavigatorOptions: { //[Optional] Options to specify and enable arrow navigator or not
                        $Class: $JssorArrowNavigator$, //[Requried] Class to create arrow navigator instance
                        $ChanceToShow: 0, //[Required] 0 Never, 1 Mouse Over, 2 Always
                    }
                };

                var jssor_slider1 = new $JssorSlider$("slider1_container", options);

                function ScaleSlider() {
                    var bodyWidth = document.body.clientWidth;
                    if (bodyWidth)
                        jssor_slider1.$ScaleWidth(Math.min(bodyWidth, 1920));
                    else
                        window.setTimeout(ScaleSlider, 30);
                }
                ScaleSlider();

                $(window).bind("load", ScaleSlider);
                $(window).bind("resize", ScaleSlider);
                $(window).bind("orientationchange", ScaleSlider);

                // Math the benefit box heights on the front page
                $(".benefits .benefit").matchHeight();
                $(".calendar .match").matchHeight();
                $(".commercial-chapter .match").matchHeight();
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'post_type_archive_swra_events': {
            init: function() {
                // Make a call to get the initial events data
                get_events();
            }
        },
        'tax_swra_event_category': {
            init: function() {
                // Make a call to get the initial events data
                get_events();
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
